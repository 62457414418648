<template>
  <b-modal
    id="deactive-member"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Ngừng hoạt động"
    cancel-title="Quay lại"
    ok-variant="danger"
    @ok="activeAction()"
  >
    <b-card-header class="banned-background-color deactive">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 title-modal color-primary">
          {{ $t('golf_common_deactive') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`#EA5455`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-1 pb-1 text">
        {{ $t('golf_member_confirm_deactive') }}
      </p>
    </b-card-body>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'

export default {
  props: [
    'item',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('deactive-member')
    },
    async activeAction() {
      const response = await members.deActiveMember({
        Member: {
          Id: this.item.Id,
        },
      })
      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'action_success' })
      }
    },
  },
}
</script>

<style lang="scss">
.banned-background-color.deactive {
  background: rgba(234, 84, 85, 0.12) !important;
    .color-primary {
      color: #EA5455;
    }
    border-radius: 6px 6px 0px 0px;
    .title-modal {
      padding: 10px 0;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
    }
    .text {
      color: #5D5F65;
      font-size: 12px;
      line-height: 18px;
    }
}
// [dir] .table th, [dir] .table td {
//     padding: 0 1rem;
//     height: 48px;
// }
</style>
