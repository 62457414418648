<template>
  <b-modal
    id="delete-member"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Xoá"
    cancel-title="Quay lại"
    ok-variant="danger"
    @ok="deleteAction()"
  >
    <b-card-header class="banned-background-color deleted">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 pt-1 pb-1 title-modal color-red">
          {{ $t('golf_member_delete') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`red`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-1 pb-1 text">
        {{ $t('golf_member_confirm_delete') }}
      </p>
    </b-card-body>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'

export default {
  props: [
    'item',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('delete-member')
    },
    async deleteAction() {
      const response = await members.deleteMember({
        Member: {
          Id: this.item.Id,
        },
      })
      this.showResToast(response)

      if (response.Status === '200') {
        this.$emit('event', { type: 'save_success' })
      }
    },
  },
}
</script>

<style lang="scss">
.banned-background-color.deleted {
  height: 48px !important;
  background: rgba(234, 84, 85, 0.12) !important;
  .block-header {
    height: 48px !important;
  }
  .color-red {
    color: #EA5455;
  }
  border-radius: 6px 6px 0px 0px;
  .title-modal {
    padding: 10px 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }
  .text {
      color: #5D5F65;
      font-size: 12px;
      line-height: 18px;
    }
}
.modal-footer {
  height: 63px !important;
}
</style>
