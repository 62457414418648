<template>
  <b-modal
    id="active-member"
    size="sm"
    centered
    hide-header
    cancel-variant="none"
    ok-title="Kích hoạt lại"
    cancel-title="Quay lại"
    ok-variant="primary"
    @ok="activeAction()"
  >
    <b-card-header class="banned-background-color active">
      <div class="d-flex justify-content-between align-items-center block-header">
        <p class="mb-0 pt-1 pb-1 title-modal color-primary">
          {{ $t('golf_member_reactive') }}
        </p>
        <ez-icon
          icon="ezGolf-icon-x"
          :color="`#114a9f`"
          class="cursor"
          :size="24"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <p class="mb-0 pt-1 pb-1 text">
        {{ $t('golf_member_confirm_active') }}
      </p>
    </b-card-body>
  </b-modal>
</template>

<script>
import { members } from '@/api/members'

export default {
  props: [
    'item',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('active-member')
    },

    async activeAction() {
      const response = await members.activeMember({
        Member: {
          Id: this.item.Id,
        },
      })

      if (response.Status === '200') {
        this.$emit('event', { type: 'action_success' })
      }

      this.showResToast(response)
    },
  },
}
</script>

<style lang="scss">
.banned-background-color.active {
  background: rgba(17, 74, 159, 0.12) !important;

  .color-primary {
    color: #114A9F;
  }

  border-radius: 6px 6px 0px 0px;

  .title-modal {
    padding: 10px 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }

  .text {
    color: #5D5F65;
    font-size: 12px;
    line-height: 18px;
  }
}

</style>
