<template>
    <div>
        <b-modal
            id="modal-merge-member"
            v-model="isOpen"
            size="xl"
            :no-close-on-backdrop="true"
        >
            <template #modal-header="{ close }">
                <h4 class="mb-0 text-primary">
                    {{ $t('golf_modal_merge_member_title') }}
                </h4>
                <ez-icon
                    icon="ezGolf-icon-x"
                    class="cursor-pointer"
                    size="24"
                    color="#114A9F"
                    @click="close"
                />
            </template>
            <b-card-body>
                <DxDataGrid
                    ref="table-merge-member"
                    class="table-merge-member"
                    height="500"
                    :show-borders="false"
                    :data-source="listMember"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    column-resizing-mode="widget"
                    :column-auto-width="true"
                    >
                    <DxColumn
                        v-for="(item, i) in configTableImport"
                        :key="i.field"
                        :caption="item.caption"
                        :data-field="item.field"
                        :data-type="item.type"
                        :alignment="item.alignment"
                        :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
                        width="auto"
                        :allow-editing="true"
                    >
                        
                        <DxColumn
                        v-for="(band, j) in item.bands"
                        :key="band.field"
                        :caption="band.caption"
                        :data-field="band.field"
                        :data-type="band.type"
                        :group-cell-template="customText"
                        :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                        :alignment="band.alignment"
                        width="auto"
                        :allow-editing="true"
                        />
                        </DxColumn>
                        <DxSelection
                            :select-all-mode="allMode"
                            :show-check-boxes-mode="checkBoxesMode"
                            mode="single"
                            />
                        
                        <!-- Bật cố định column -->
                        <DxColumnFixing :enabled="true" />

                        <!-- Cho phép group -->
                        <DxGroupPanel :visible="false" />

                        <!-- Phân trang -->
                        <DxPaging :page-size="perPage" />
                        <DxPager
                        :visible="true"
                        display-mode="full"
                        :show-info="true"
                        />
                    </DxDataGrid>
            </b-card-body>
            <template #modal-footer>
                <b-button
                size="md"
                variant="primary"
                @click="save"
                >
                {{ $t('golf_common_confirm') }}
                </b-button>
            </template>
            </b-modal>
    </div>
</template>
<script>
const dataGridRef = 'table-merge-member'
import {
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import { members } from '@/api/members'
export default {
    components: {
        DxTexts,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
    },
    props: ['data'],
    data() {
        return {
            configTableImport: [
                {
                caption: 'Họ tên',
                field: 'FullName',
                isShow: true,
                alignment: 'center',
                },
                {
                caption: 'Lời chào',
                field: 'Greeting',
                isShow: true,
                alignment: 'center',
                },
                {
                caption: 'Danh xưng',
                field: 'Aliases',
                isShow: true,
                alignment: 'center',
                },
                {
                caption: 'Nghề nghiệp',
                field: 'Job',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Chức vụ',
                field: 'Position',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Thành phố',
                field: 'City',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Zipcode',
                field: 'Zipcode',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Mã quốc gia',
                field: 'NationalityCode',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'CCCD/CMND',
                field: 'IdentityCard',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Nơi cấp CCCD/CMND',
                field: 'IdentityCardIssuer',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Ngày cấp CCCD/CMND',
                field: 'IdentityIssueDate',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Visa',
                field: 'Visa',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Nơi cấp visa',
                field: 'VisaCardIssuer',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Ngày cấp visa',
                field: 'VisaIssueDate',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Mã giới tính',
                field: 'SexCode',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Ngày sinh',
                field: 'Birthday',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Địa chỉ',
                field: 'Address',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Địa chỉ khác',
                field: 'Address1',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Số điện thoại',
                field: 'PhoneNumber',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Email',
                field: 'Email',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Fax',
                field: 'Fax',
                isShow: true,
                alignment: 'center',
                },
                {
                caption: 'Mã VGA',
                field: 'Vgacode',
                isShow: true,
                alignment: 'center',
                },{
                caption: 'Ghi chú',
                field: 'Note',
                isShow: true,
                alignment: 'center',
                }
            ],
            listMember: [],
            perPage: 50,
            optionPerPage: [10, 50, 100],
            isOpen: false,
            allMode: 'allPages',
            checkBoxesMode: 'onClick', 
            selectedRowsData: []
        }
    },
    methods: {
        getSelectedData() {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData()
        },
        async save() {
            this.getSelectedData()
            if(this.selectedRowsData.length > 0) {
                const body = {
                    MemberIdSource: this.listMember.filter(x => x.Id != this.selectedRowsData[0].Id).map(y => y.Id),
                    MemberIdDestination: this.selectedRowsData[0].Id
                }
                await members.mergeMember(body).then(async res => {
                    if(res.Status == 200) {
                        this.listMember.filter(x => x.Id != this.selectedRowsData[0].Id).map(y => y.Id).forEach(async (z, index) => {
                            await members.deleteMember({
                                Member: {
                                Id: z,
                                },
                            })
                            if(index == this.listMember.filter(x => x.Id != this.selectedRowsData[0].Id).map(y => y.Id).length - 1) {
                                this.$bvModal.hide('modal-merge-member')
                                this.$emit('event', { type: 'action_success' })
                            }
                        })
                    }
                    this.showResToast(res)
                })
                console.log(body)
            } else {
                this.showToast('error', this.$t('golf_member_please_select_member'))
            }
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.listMember = this.data
            }
        }
    },
    computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
}
</script>
<style lang="scss">
    
</style>