import { members } from '@/api/members'
import { golfClass } from '@/api/golf-class'
import vSelect from 'vue-select'
import { commonServices } from '@/api/common-services'
import ExcelJS from "exceljs";
import readXlsxFile from 'read-excel-file';
import { required } from '@validations'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { memberCardSetting } from '@/api/member-card-setting'
import DxButton from 'devextreme-vue/button'
import { booking } from '@/api/booking'
import MergeMember from './modals/MergeMember.vue'
import {
  DxTexts,
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import SidebarContent from './SidebarContent.vue'
import DeleteMember from './modals/DeleteMember'
import ActiveMember from './modals/ActiveMember'
import DeActiveMember from './modals/DeActiveMember'
import 'devextreme/dist/css/dx.light.css'
import { watch } from 'vue';

const ResponseStatusSuccess = '200'
const dataGridRef = 'table-member'

export default {
  name: 'Members',
  components: {
    SidebarContent,
    DeleteMember,
    ActiveMember,
    DeActiveMember,
    required,
    DxButton,
    DxTexts,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    MergeMember
  },
  data() {
    return {
      isCreate: false,
      submitted: false,
      searchKey: '',
      rows: 100,
      currentPage: 1,
      exportStyle: null,
      selectedCard: null,
      selectedMemberType: null,
      selectedGolfClass: null,
      selectedFilter: null,
      cardType: [],
      countries: [],
      companies: [],
      courseSelected: [],
      tabs: true,
      listGolfClass: [],
      page: 5,
      pages: [
        { value: null, text: 'Chọn' },
        { value: '5', text: 5 },
        { value: '10', text: 10 },
        { value: '15', text: 15 },
        { value: '20', text: 20 },
      ],
      items: [],
      remoteOperations:{
        paging: true,
      },
      items1: {
        data: null,
        totalCount: 0,
        summary: 0,
        groupCount: 0,
      },
      actionData: null,
      Member: {
        InActive: false
      },
      isBusyTable: false,
      listBooking: [],
      perPage: 50,
      totalRow: 0,
      optionPerPage: [10, 50, 100],
      selectShow: ['ALL'],
      Keywordcardcode: null,
      configTable: [
        {
          caption: '',
          field: 'Id',
          isShow: true,
          allowEditing: false,
          cellTemplate: 'Id',
          width: '1px'
        },
        {
          caption: this.$t('golf_common_first_and_last_name'),
          field: 'MemberName',
          isShow: true,
          allowEditing: false,
        },
        {
          caption: this.$t('golf_common_phone'),
          field: 'PhoneNumber',
          isShow: true,
          allowEditing: false,
        },
        {
          caption: 'Email',
          field: 'Email',
          isShow: true,
          allowEditing: false,
        },
        {
          caption: this.$t('golf_member_country'),
          field: 'NationalityName',
          isShow: true,
          allowEditing: false,
        },
        {
          caption: this.$t('golf_member_card_code_2'),
          field: 'IdentityCard',
          isShow: true,
          cellTemplate: 'indentity-card',
          allowEditing: false,
        },
        {
          caption: this.$t('golf_common_guest_type'),
          field: 'MemberType',
          cellTemplate: 'member-type',
          isShow: true,
          allowEditing: false,
        },
        {
          caption: this.$t('golf_common_golf_class'),
          field: 'GolfClassId',
          isShow: true,
          cellTemplate: 'golf-class',
          allowEditing: false,
        },
        {
          caption: this.$t('golf_member_card_type'),
          field: 'CardTypeCustom',
          isShow: true,
          cellTemplate: 'card-type',
          allowEditing: false,
        },
        {
          caption: this.$t('golf_common_company'),
          field: 'CompanyName',
          isShow: true,
          allowEditing: true,
        },
        {
          caption: this.$t('golf_member_member_potition'),
          field: 'Position',
          isShow: true,
          allowEditing: true,
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
          allowEditing: false,
        },
      ],
      isLoading: false,
      NationalityCodeDefault: null,
      isOpenSideBarMember: false,
      listMemberImport: [],
      configTableImport: [
        {
          caption: 'STT',
          field: 'Seq',
          isShow: true,
          alignment: 'center',
        },
        {
          caption: 'Họ tên',
          field: 'FullName',
          isShow: true,
          alignment: 'center',
        },
        {
          caption: 'Lời chào',
          field: 'Greeting',
          isShow: true,
          alignment: 'center',
        },
        {
          caption: 'Danh xưng',
          field: 'Aliases',
          isShow: true,
          alignment: 'center',
        },
        {
          caption: 'Nghề nghiệp',
          field: 'Job',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Chức vụ',
          field: 'Position',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Thành phố',
          field: 'City',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Zipcode',
          field: 'Zipcode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Mã quốc gia',
          field: 'NationalityCode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'CCCD/CMND',
          field: 'IdentityCard',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Nơi cấp CCCD/CMND',
          field: 'IdentityCardIssuer',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ngày cấp CCCD/CMND',
          field: 'IdentityIssueDate',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Visa',
          field: 'Visa',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Nơi cấp visa',
          field: 'VisaCardIssuer',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ngày cấp visa',
          field: 'VisaIssueDate',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Mã giới tính',
          field: 'SexCode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ngày sinh',
          field: 'Birthday',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Địa chỉ',
          field: 'Address',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Địa chỉ khác',
          field: 'Address1',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Số điện thoại',
          field: 'PhoneNumber',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Email',
          field: 'Email',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Fax',
          field: 'Fax',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Loại khách',
          field: 'MemberType',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Mã thẻ',
          field: 'Card_CardCode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Loại thẻ hội viên',
          field: 'Card_CardTypeCode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Mã hợp đồng',
          field: 'Card_ContractCode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Giá trị hợp đồng',
          field: 'Card_ContractValue',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Số lần chơi',
          field: 'Card_NumberOfPlays',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ngày bắt đầu hợp đồng',
          field: 'Card_OpenDate',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ngày kết thúc hợp đồng',
          field: 'Card_EndDate',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ghi chú hợp đồng',
          field: 'Card_Note',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Số lần chuyển nhượng',
          field: 'Card_TransferTurn',
          isShow: true,
          alignment: 'center',
        },
        {
          caption: 'Mã VGA',
          field: 'Vgacode',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Ghi chú',
          field: 'Note',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Kiểm tra 1',
          field: 'Error',
          isShow: true,
          alignment: 'center',
        },{
          caption: 'Kiểm tra 2',
          field: 'Error2',
          isShow: true,
          alignment: 'center',
        }
      ],
      fileImportName: null,
      listSexCode: null,
      currentLang: this.$i18n.locale,
      KeywordVgaCode: null,
      allMode: 'allPages',
      checkBoxesMode: 'onClick', // 'always' :
      selectedRowsData: [],
      listMemberProps: []
    }
  },
  async created() {
    await commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSexCode = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
    await this.getConfigInfo_RCOM01()
    await this.refreshData()
    await this.getGolfClass()
    await this.getCountries_RNA01()
    await this.getCompanies_RCL01()
    await this.getListMember_RMEC01()
    await this.getListMember_RME01()
  },
  watch: {
     currentPage:function ( newVal){
       this.getListMember_RME01()
    },
    perPage: function ( newVal){
       this.getListMember_RME01()
    }
  },
  methods: {
    handleEvent(result) {
      switch (result.type) {
        case 'save_success':
        case 'action_success':
          this.getListMember_RME01()
          break
        case 'deleteMember':
          this.handleAction(result.Member, 'delete-member')
          break
        case 'activeMember':
          this.handleAction(result.Member, 'active-member')
          break
        case 'deActiveMember':
          this.handleAction(result.Member, 'deactive-member')
          break
      }
    },
    async getGolfClass() {
      const CourseId = this.courseSelected.map(item => item.Id)
      const response = await golfClass.getListGolfClass({ CourseId })
      if (response) {
        this.listGolfClass = response?.Data?.GolfClass
      }
    },
    async getListMember_RME01() {
      this.isLoading = true
      const requestData = {
        MemberName: this.searchKey,
        MemberCode: null,
        GolfClassId: this.selectedGolfClass,
        CardTypeId: this.selectedCard && this.selectedCard.length ? this.selectedCard.map(x => x.Id) : null,
        MemberType: this.selectedMemberType,
        CardCode: this.Keywordcardcode,
        Vgacode: this.KeywordVgaCode,
        Page: this.currentPage,
        PageSize: this.perPage,
        InActive: null,
      }
      this.isBusyTable = true
      const response = await members.getListMember(requestData)
      this.isBusyTable = false
      if (response.Status === ResponseStatusSuccess) {
        this.items = response?.Data?.Member
        this.totalRow = response?.Data?.TotalRecordings
      }
      this.isLoading = false
    },
    async getListMember_RMEC01() {
      const requestData = {
        KeyWord: null,
        CourseId: null,
      }

      const response = await memberCardSetting.getListMemberCard(requestData)
      if (response) {
        this.cardType = response?.Data?.MemberCard
      }
    },
    async myRowClickHandler(record, index) {
      await this.getDetailMember_RME02(record.Id)
    },
    async search() {
      await this.getListMember_RME01()
      await this.getGolfClass()
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    showCreateForm() {
      this.isCreate = true
      this.refreshData()
      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },
    refreshData() {
      this.Member = {
        Avatar: null,
        FirstName: null,
        LastName: null,
        Aliases: null,
        MemberType: 'VISITOR',
        MemberCode: null,
        Greeting: null,
        Birthday: null, // this.getUTC('start', 'year'), // null,
        SexCode: null,
        PhoneNumber: null,
        Fax: null,
        NationalityCode: this.NationalityCodeDefault,
        Zipcode: null,
        Email: null,
        City: null,
        Address: null,
        Address1: null,
        Passport: null,
        Visa: null,
        VisaIssueDate: null,
        VisaCardIssuer: null,
        IdentityCard: null,
        IdentityIssueDate: null,
        IdentityCardIssuer: null,
        CompanyId: 1,
        Job: null,
        Position: null,
        Note: null,
        CreateBy: null,
        CreateDate: null,
        UpdateBy: null,
        UpdateDate: null,
        InActive: false,
        IsDelete: false,
        Vgacode: null
      }
    },
    async getDetailMember_RME02(id) {
      this.isCreate = false
      const response = await members.detailMember({
        Member: {
          Id: id,
        },
      })
      if (response.Status === ResponseStatusSuccess) {
        this.Member = response?.Data?.Member
        this.toggleSidebar()
      }
    },
    displayGolfClassCode(GolfClassId) {
      const response = this.listGolfClass.filter(x => x.Id === GolfClassId)
      if (response.length) {
        return response[0]
      }

      return {}
    },
    displayCompanyName(CompanyId) {
      const response = this.companies.find(company => company.Id === CompanyId)
      if (response) {
        return response
      }

      return {}
    },
    handleAction(item, id = null) {
      this.actionData = item
      if (id) {
        this.showModal(id)
      }
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.InActive === true) {
        return 'isOver'
      }
    },
    toggleSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },
    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries = response.Data.Country
      }
    },
    async getCompanies_RCL01() {
      const response = await members.getCompanies({})
      if (response) {
        this.companies = response.Data.Client
      }
    },
    getGuestType(guestMemberType) {
      const memberTypeDetail = (this.memberType ?? []).find(x => x.value === guestMemberType)
      if (typeof memberTypeDetail !== 'undefined' && 'text' in memberTypeDetail) {
        return memberTypeDetail.text || ''
      }
      return guestMemberType || ''
    },
    getCardType(memberSubscriptions) {
      const cardTypeNames = [];
      (memberSubscriptions ?? []).forEach(memberSubscription => {
        if ('MemberCard' in memberSubscription && 'CardTypeName' in memberSubscription.MemberCard) {
          cardTypeNames.push(memberSubscription.MemberCard.CardTypeName)
        }
      })
      return cardTypeNames
    },
    viewDetailRate(item) {
      console.log(item)
      if (item.column.name != 'action' && item.column.type != "selection" && item.data.Id) {
        this.getDetailMember_RME02(item.data.Id)
      }
    },
    exportExcel() {
      const listGolfClass = this.listGolfClass
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Booking')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function(options) {
          const { gridCell, excelCell } = options;
          if(gridCell.rowType === 'data') {
            if(gridCell.column.dataField == 'IdentityCard') {
              if(gridCell.data.MemberSubscription && Array.isArray(gridCell.data.MemberSubscription) && gridCell.data.MemberSubscription.length > 0) {
                let CardCodeTemp = []
                gridCell.data.MemberSubscription.forEach(x => {
                  CardCodeTemp.push(x.CardCode)
                })
                excelCell.value = CardCodeTemp.join()
              } else {
                excelCell.value = ''
              }
            }
            if(gridCell.column.dataField == 'GolfClassId') {
              if(gridCell.data.MemberSubscription && Array.isArray(gridCell.data.MemberSubscription) && gridCell.data.MemberSubscription.length > 0) {
                let GolfClassTemp = []
                gridCell.data.MemberSubscription.forEach(x => {
                  const golfClass = listGolfClass.find(y => y.Id === x.MemberCard.GolfClassId)
                  if(golfClass) {
                    GolfClassTemp.push(golfClass.GolfClassName)
                  }
                })
                excelCell.value = GolfClassTemp.join()
              } else {
                excelCell.value = ''
              }
            }
            if(gridCell.column.dataField == 'CardTypeCustom') {
              if(gridCell.data.MemberSubscription && Array.isArray(gridCell.data.MemberSubscription) && gridCell.data.MemberSubscription.length > 0) {
                let CardTypeNameTemp = []
                gridCell.data.MemberSubscription.forEach(x => {
                  if(x.MemberCard.CardTypeName) {
                    CardTypeNameTemp.push(x.MemberCard.CardTypeName)
                  }
                })
                excelCell.value = CardTypeNameTemp.join()
              } else {
                excelCell.value = ''
              }
            }
      } }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Danh sách khách hàng'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Member.xlsx')
        })
      })
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.NationalityCodeDefault = response?.Data.find(x => x.KeyCode === 'CUSTOMER_NATIONALITY_DEFAULT').KeyValue
      }
    },
    generateXLSX() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Format Member");
      const worksheetNational = workbook.addWorksheet("Nation");
      const worksheetSexCode = workbook.addWorksheet("SexCode");
      const worksheetFormatDate = workbook.addWorksheet("FormatDate");

      worksheet.columns = [
          { header: "Họ tên", key: "FullName"},
          { header: "Lời chào", key: "Greeting"},
          { header: "Danh xưng", key: "Aliases"},
          { header: "Nghề nghiệp", key: "Job"},
          { header: "Chức vụ", key: "Position"},
          { header: "Thành phố", key: "City"},
          { header: "Zipcode", key: "Zipcode"},
          { header: "Mã quốc gia", key: "NationalityCode"},
          { header: "CCCD/CMND", key: "IdentityCard"},
          { header: "Nơi cấp CCCD/CMND", key: "IdentityCardIssuer"},
          { header: "Ngày cấp CCCD/CMND", key: "IdentityIssueDate"},
          { header: "Visa", key: "Visa"},
          { header: "Nơi cấp visa", key: "VisaCardIssuer"},
          { header: "Ngày cấp visa", key: "VisaIssueDate"},
          { header: "Mã giới tính", key: "SexCode"},
          { header: "Ngày sinh", key: "Birthday"},
          { header: "Địa chỉ", key: "Address"},
          { header: "Địa chỉ khác", key: "Address1"},
          { header: "Số điện thoại", key: "PhoneNumber"},
          { header: "Email", key: "Email"},
          { header: "Fax", key: "Fax"},
          { header: "Loại khách", key: "MemberType"},
          { header: "Mã thẻ", key: "Card_CardCode"},
          { header: "Loại thẻ hội viên", key: "Card_CardTypeCode"},
          { header: "Mã hợp đồng", key: "Card_ContractCode"},
          { header: "Giá trị hợp đồng", key: "Card_ContractValue"},
          { header: "Lượt chơi", key: "Card_NumberOfPlays"},
          { header: "Ngày bắt đầu hợp đồng", key: "Card_OpenDate"},
          { header: "Ngày kết thúc hợp đồng", key: " Card_EndDate"},
          { header: "Ghi chú hợp đồng", key: "Card_Note"},
          { header: "Số lần chuyển nhượng hợp đồng", key: "Card_TransferTurn"},
          { header: "Mã VGA", key: "Vgacode"},
          { header: "Ghi chú", key: "Note"},
      ];
      worksheetNational.columns = [
        { header: "Mã quốc tịch", key: "ID"},
        { header: "Tên quốc tịch VN", key: "CountryNameVN"},
        { header: "Tên quốc tịch EN", key: "CountryNameEN"},
      ];
      this.countries.forEach(x => {
        worksheetNational.addRow(x)
      })
      worksheetFormatDate.columns = [
        { header: "Format Date", key: "key"},
      ];
      worksheetFormatDate.addRow({key: '01/01/2001'})
      worksheetSexCode.columns = [
        { header: "Mã giới tính", key: "KeyCode"},
        { header: "Giới tính", key: "KeyValue"},
      ];
      this.listSexCode.forEach(x => {
        worksheetSexCode.addRow(x)
      })
      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `TemplateMemberExport.xlsx`)
      })
    },
    triggerClickChooFiles() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.click();
    },
    clearFile() {
      const fromChooseFile = document.querySelector('.form-import-excel__field-choosefile');
      fromChooseFile.value = ""
      this.fileImportName = null
    },
    readFileExcel() {
      this.fileImportName = this.$refs.choosefileExcel.files[0].name
      this.listMemberImport = []
      readXlsxFile(this.$refs.choosefileExcel.files[0]).then((rows) => {
          rows.forEach((item,index) => {
            if(index > 0) {
              this.listMemberImport.push(
                {
                  Seq: index,
                  FullName: item[0],
                  Greeting: item[1],
                  Aliases: item[2],
                  Job: item[3],
                  Position: item[4],
                  City: item[5],
                  Zipcode: item[6] ? String(item[6]) : null,
                  NationalityCode: item[7] ? this.countries.find(contries => contries.ID.trim().toUpperCase() == item[7].trim().toUpperCase()) ? this.countries.find(contries => contries.ID.trim().toUpperCase() == item[7].trim().toUpperCase()).ID : null : null,
                  IdentityCard: item[8] ? String(item[8]) : null,
                  IdentityCardIssuer: item[9],
                  IdentityIssueDate: item[10],
                  Visa: item[11] ? String(item[11]) : null,
                  VisaCardIssuer: item[12],
                  VisaIssueDate: item[13],
                  SexCode: item[14] ? this.listSexCode.find(z => z.KeyCode.trim().toUpperCase() == String(item[14]).trim().toUpperCase()) ? this.listSexCode.find(z => z.KeyCode.trim().toUpperCase() == item[14].trim().toUpperCase()).KeyCode : null : null,
                  Birthday: item[15],
                  Address: item[16],
                  Address1: item[17],
                  PhoneNumber: item[18] ? String(item[18]) : null,
                  Email: item[19],
                  Fax: item[20],
                  MemberType: item[22] ? 'MEMBER' : 'VISTOR',
                  Card_CardCode: item[22] ? String(item[22]) : null,
                  Card_CardTypeCode: item[23] ? this.cardType.find(card => card.CardTypeCode.trim().toUpperCase() == item[23].trim().toUpperCase()) ? this.cardType.find(card => card.CardTypeCode.trim().toUpperCase() == item[23].trim().toUpperCase()).CardTypeCode : null : null ,
                  Card_ContractCode: item[24] ? String(item[24]) : null,
                  Card_ContractValue: Number(item[25]) ? Number(item[25]) : 0,
                  Card_NumberOfPlays: Number(item[26]) ? Number(item[26]) : 0,
                  Card_OpenDate: item[27],
                  Card_EndDate: item[28],
                  Card_Note: item[29],
                  Card_TransferTurn: Number(item[30]) ? Number(item[30]) : null,
                  Vgacode: item[31] ? String(item[31]) : null,
                  Note: item[32],
                  Error: '',
                  Error2: ''
                }
              )
              if(item[14] && !this.listSexCode.find(z => z.KeyCode.trim().toUpperCase() == String(item[14]).trim().toUpperCase())) {
                this.listMemberImport[index - 1].Error += '- SexCode không phù hợp'
              }
              if(item[7] && !this.countries.find(country => country.ID == item[7].trim().toUpperCase())) {
                this.listMemberImport[index - 1].Error += '- Quốc tịch không tồn tại'
              }
              if(item[23] && !this.cardType.find(card => card.CardTypeCode.trim().toUpperCase() == item[23].trim().toUpperCase())) {
                this.listMemberImport[index - 1].Error += '- Loại thẻ không tồn tại'
              }
              if(item[15]) {
                if(!this.validateForm(item[15])) {
                  this.listMemberImport[index - 1].Error += '- Birthday chưa đúng định dạng'
                }
              }
              if(item[10]) {
                if(!this.validateForm(item[10])) {
                  this.listMemberImport[index - 1].Error += '- IdentityIssueDate chưa đúng định dạng'
                }
              }
              if(item[13]) {
                if(!this.validateForm(item[13])) {
                  this.listMemberImport[index - 1].Error += '- VisaIssueDate chưa đúng định dạng'
                }
              }
              if(item[27]) {
                if(!this.validateForm(item[27])) {
                  this.listMemberImport[index - 1].Error += '- Card_OpenDate chưa đúng định dạng'
                }
              }
              if(item[28]) {
                if(!this.validateForm(item[28])) {
                  this.listMemberImport[index - 1].Error += '- Card_EndDate chưa đúng định dạng'
                }
              }
              if(item[19]) {
                if(!this.checkFormEmail(item[19])) {
                  this.listMemberImport[index - 1].Error += '- Email không hợp lệ'
                }
              }
            }
          });
          this.$bvModal.show('import-member')
      });
    },
    hideModalImportMember() {
      this.$bvModal.hide('import-member')
    },
    ErrorRow(e) {
      if (e.rowType !== 'data') { return }
      if (e.data.Error != '' || e.data.Error2 != '') {
        e.rowElement.style.backgroundColor = 'rgba(255, 48 ,48, 0.3)'
      }
    },
    RowInActive(e) {
      if (e.rowType !== 'data') { return }
      if (e.data.InActive) {
        e.rowElement.style.fontStyle = 'italic'
        e.rowElement.style.backgroundColor = 'rgba(193, 193, 193, 0.6)'
      }
    },
    rowUpdating(e) {
      if(e.newData.Card_CardTypeCode) {
        this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- Loại thẻ không tồn tại", "")
      } 
      if(e.newData.NationalityCode) {
        this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- Quốc tịch không tồn tại", "")
      } 
      if(e.newData.SexCode) {
        this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- SexCode không phù hợp", "")
      } 
      if(e.newData.Birthday || e.newData.Birthday == '') {
        if(e.newData.Birthday != '') {
          this.checkEditFormatDate(e.newData.Birthday, e.oldData.Seq, e.oldData.Error, "- Birthday chưa đúng định dạng")
        } else {
          this.listMemberImport[e.oldData.Seq - 1].Birthday = null
          this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- Birthday chưa đúng định dạng", "")
        }
      }
      if(e.newData.IdentityIssueDate || e.newData.IdentityIssueDate == '') {
        if(e.newData.IdentityIssueDate != '') {
          this.checkEditFormatDate(e.newData.IdentityIssueDate, e.oldData.Seq, e.oldData.Error, "- IdentityIssueDate chưa đúng định dạng")
        } else {
          this.listMemberImport[e.oldData.Seq - 1].IdentityIssueDate = null
          this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- IdentityIssueDate chưa đúng định dạng", "")
        }
      }
      if(e.newData.VisaIssueDate || e.newData.VisaIssueDate == '') {
        if(e.newData.VisaIssueDate != '') {
          this.checkEditFormatDate(e.newData.VisaIssueDate, e.oldData.Seq, e.oldData.Error, "- VisaIssueDate chưa đúng định dạng")
        } else {
          this.listMemberImport[e.oldData.Seq - 1].VisaIssueDate = null
          this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- VisaIssueDate chưa đúng định dạng", "")
        }
      }
      if(e.newData.Card_OpenDate || e.newData.Card_OpenDate == '') {
        if(e.newData.Card_OpenDate != '') {
          this.checkEditFormatDate(e.newData.Card_OpenDate, e.oldData.Seq, e.oldData.Error, "- Card_OpenDate chưa đúng định dạng")
        } else {
          this.listMemberImport[e.oldData.Seq - 1].Card_OpenDate = null
          this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- Card_OpenDate chưa đúng định dạng", "")
        }
      }
      if(e.newData.Card_EndDate || e.newData.Card_EndDate == '') {
        if(e.newData.Card_EndDate != '') {
          this.checkEditFormatDate(e.newData.Card_EndDate, e.oldData.Seq, e.oldData.Error, "- Card_EndDate chưa đúng định dạng")
        } else {
          this.listMemberImport[e.oldData.Seq - 1].Card_EndDate = null
          this.listMemberImport[e.oldData.Seq - 1].Error = e.oldData.Error.replace("- Card_EndDate chưa đúng định dạng", "")
        }
      }
    },
    checkEditFormatDate(date, Seq ,textError, text) {
      if(!this.validateForm(date)) {
        if(!textError.includes(text)) {
          this.listMemberImport[Seq - 1].Error += text
        }
      } else {
        this.listMemberImport[Seq - 1].Error = textError.replace(text, "")
      }
     },
     checkFormEmail(text) {
        const check =  text.includes('@')
        return check
     },
    async SaveImportMember() {
      
      let ListError = this.listMemberImport.filter(x => x.Error != '')
      if(ListError.length == 0) {
        const body = {
          Member: JSON.parse(JSON.stringify(this.listMemberImport))
        }
        body.Member.forEach(x => {
          x.Birthday = x.Birthday ? this.convertToUTC0(new Date(x.Birthday.split('/')[2],x.Birthday.split('/')[1] - 1,x.Birthday.split('/')[0],0,0,0)) : null
          x.VisaIssueDate = x.VisaIssueDate ? this.convertToUTC0(new Date(x.VisaIssueDate.split('/')[2],x.VisaIssueDate.split('/')[1] - 1,x.VisaIssueDate.split('/')[0],0,0,0)) : null
          x.IdentityIssueDate = x.IdentityIssueDate ? this.convertToUTC0(new Date(x.IdentityIssueDate.split('/')[2],x.IdentityIssueDate.split('/')[1] - 1,x.IdentityIssueDate.split('/')[0],0,0,0)) : null
          x.Card_OpenDate = x.Card_OpenDate ? this.convertToUTC0(new Date(x.Card_OpenDate.split('/')[2],x.Card_OpenDate.split('/')[1] - 1,x.Card_OpenDate.split('/')[0],0,0,0)) : null
          x.Card_EndDate = x.Card_EndDate ? this.convertToUTC0(new Date(x.Card_EndDate.split('/')[2],x.Card_EndDate.split('/')[1] - 1,x.Card_EndDate.split('/')[0],0,0,0)) : null
        })
        await members.api_IME02(body)
        .then(res => { 
          if(res.Status == 200) {
            this.search()
            this.hideModalImportMember()
          } else if(res.Status == 400){
            res.Data.Member.forEach(x => {
              this.listMemberImport[x.Seq - 1].Error2 = x.Message.join()
            })
          }
        })
      } else {
        this.showToast('error', `Danh sách member chưa phù hợp hoặc chưa đúng định dạng. Vui lòng kiểm tra lại`)
      }
    },
    convertToUTC0(date) {
      const timeZone = localStorage.getItem('timeZone')
      const offset = this.dayjs().utc().tz(timeZone).startOf('day')
        .utcOffset()
      return this.dayjs(date)
        .add(-offset, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    },
    validateForm(input){
      var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
      if (input.match(reg)) {
        return true
      }
      else {
        return false
      }
    },
    openMergeMember() {
      this.getSelectedData()
      if (this.selectedRowsData.length) {
        this.listMemberProps = []
        this.selectedRowsData.forEach(async (x, index) => {
          const response = await members.detailMember({
              Member: {
                  Id: x.Id,
              },
          })
          if(response.Status == 200) {
            this.listMemberProps.push(response.Data.Member)
          }
          if(index == this.selectedRowsData.length - 1) {
            this.$bvModal.show('modal-merge-member')
          }
        });
      } else {
        this.showToast('error', this.$t('golf_member_please_select_member'))
      }
    },
    getSelectedData() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    }
  },
  computed: {
    exportOptions() {
      return [
        { value: null, text: this.$t('golf_common_export') },
        { value: 1, text: this.$t('golf_common_export_to_pdf') },
        { value: 2, text: this.$t('golf_common_export_to_excel') },
      ]
    },
    memberType() {
      return [
        // { value: null, text: this.$t('golf_common_guest_type') },
        { value: 'MEMBER', text: 'Member' },
        { value: 'VISITOR', text: 'Visitor' },
      ]
    },
    fieldsMemberTable() {
      return [
        { key: 'MemberName', label: this.$t('golf_common_first_and_last_name'), sortable: true },
        { key: 'PhoneNumber', label: this.$t('golf_common_phone'), sortable: true },
        { key: 'Email', label: this.$t('golf_common_email'), sortable: true },
        { key: 'IdentityCard', label: this.$t('golf_member_card_code_2'), sortable: true },
        { key: 'MemberType', label: this.$t('golf_common_guest_type'), sortable: true },
        { key: 'GolfClassId', label: this.$t('golf_common_golf_class'), sortable: true },
        { key: 'CardTypeCustom', label: this.$t('golf_member_card_type') },
        { key: 'CompanyName', label: this.$t('golf_common_company') },
        { key: 'action', label: '' },
      ]
    },
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
}
