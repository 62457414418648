/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_MEMBER_CARD_API,
  CREATE_MEMBER_CARD_API,
  UPDATE_MEMBER_CARD_API,
  DETAIL_MEMBER_CARD_API,
  ACTIVE_MEMBER_CARD_API,
  DEACTIVE_MEMBER_CARD_API, DELETE_MEMBER_CARD_API
} from '@/api/constant'
import {handleResponse} from '@/api/handle-response'
import {requestOptions} from '@/api/request-options'

function getListMemberCard(data) {
  return fetch(ROOT + GET_LIST_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function createCard(data) {
  return fetch(ROOT + CREATE_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function updateCard(data) {
  return fetch(ROOT + UPDATE_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function activeCard(data) {
  return fetch(ROOT + ACTIVE_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function deActiveCard(data) {
  return fetch(ROOT + DEACTIVE_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function deleteCard(data) {
  return fetch(ROOT + DELETE_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

function detailCard(data) {
  return fetch(ROOT + DETAIL_MEMBER_CARD_API, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
}

export const memberCardSetting = {
  getListMemberCard,
  createCard,
  updateCard,
  detailCard,
  deleteCard,
  activeCard,
  deActiveCard,
}
